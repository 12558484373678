import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { TechnicalError } from '@orus.eu/error'
import { Text, borderStroke, colorTokens, cornerRibbonZIndex, shadow, spacing, type Color } from '@orus.eu/pharaoh'
import type { ReactElement, ReactNode } from 'react'
import { memo, useEffect, useState } from 'react'
import {
  isE2eEnvironment,
  isLocalDevEnvironment,
  isProductionEnvironment,
  isReviewOrProductionEnvironment,
} from '../../lib/env-utils'
import { useSession } from '../../lib/session'

export type PageProps = {
  children: ReactNode
}

export function Page({ children }: PageProps): ReactElement {
  return (
    <>
      {children}
      <EnvironmentHelper />
      <ImpersonationHelper />
    </>
  )
}

function EnvironmentHelper(): ReactElement {
  if (isProductionEnvironment() || isE2eEnvironment()) {
    return <></>
  }

  if (isReviewOrProductionEnvironment()) {
    return <EnvironmentRibbon name="Review Env" colorScale={colorTokens['color-bg-success-primary']} />
  }

  if (isLocalDevEnvironment()) {
    return <EnvironmentRibbon name="Local Dev" colorScale={colorTokens['color-bg-neutral']} />
  }

  throw new TechnicalError('Unexpected environment type')
}

type EnvironmentRibbonProps = {
  name: string
  colorScale: Color
}

function EnvironmentRibbon({ name, colorScale }: EnvironmentRibbonProps): ReactElement {
  // On click the ribbon will disappear for 10 seconds, so we can interact with the content behind
  // We don't hide forever, because we don't want old tabs to be confused with prod
  const [hidden, setHidden] = useState(false)

  // We need to store the timeout id, to be able to clear it when the component is dismounted
  const [timeoutId, setTimeoutId] = useState<number | null>(null)
  useEffect(() => {
    if (timeoutId !== null) {
      return () => clearTimeout(timeoutId)
    }

    return undefined
  }, [timeoutId])

  if (hidden) {
    return <></>
  }

  const hide = () => {
    setHidden(true)
    setTimeoutId(window.setTimeout(() => setHidden(false), 10000))
  }

  return (
    <div
      css={css`
        cursor: pointer;
        color: ${colorTokens['color-bg-base-normal']};
        background-color: ${colorScale};
        border: ${borderStroke[20]} solid ${colorTokens['color-bg-base-secondary']};
        box-shadow: ${shadow.bottom[40]};
        position: fixed;
        top: 0;
        left: 0;
        padding: ${spacing[30]} 100px;
        width: 320px;
        transform: translate(-50%, -50%) rotate(-45deg) translateY(80px);
        text-align: center;
        z-index: ${cornerRibbonZIndex};
      `}
      onClick={hide}
    >
      <Text variant="subtitle" element="div">
        {name}
      </Text>
      <Text variant="body2" element="div">
        Click to hide
      </Text>
    </div>
  )
}

const ImpersonationHelper = memo(function ImpersonationHelper() {
  const session = useSession()

  if (!session.impersonatorId) return <></>

  return (
    <ImpersonationOverlay>
      <Text variant="body2Medium">Impersonification en cours</Text>
    </ImpersonationOverlay>
  )
})

const ImpersonationOverlay = styled.div`
  color: ${colorTokens['color-bg-base-normal']};
  background-color: ${colorTokens['color-bg-danger-primary']};
  position: fixed;
  top: 0;
  left: 0;
  width: 500px;
  transform: translate(-50%, -50%) rotate(-45deg) translateY(120px);
  text-align: center;
  z-index: ${cornerRibbonZIndex};
`
